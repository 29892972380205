// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-works-landing-js": () => import("./../src/templates/works-landing.js" /* webpackChunkName: "component---src-templates-works-landing-js" */),
  "component---src-templates-work-template-js": () => import("./../src/templates/work-template.js" /* webpackChunkName: "component---src-templates-work-template-js" */),
  "component---src-pages-deploystatus-js": () => import("./../src/pages/@deploystatus.js" /* webpackChunkName: "component---src-pages-deploystatus-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

