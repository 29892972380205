require('./src/stylesheet/main.scss');

const React = require("react");
const Master = require("./src/components/master").default;


// Wrap The Whole Website First
exports.replaceComponentRenderer = ({ props }) => {
	return <Master>{React.createElement(props.pageResources.component, props)}</Master>;
};

exports.onRouteUpdate = ({ location }) => {
};
exports.onClientEntry = async () => {
	if (!('ontouchstart' in document.documentElement)) {
		document.body.classList.add('onhover');
	}

    const isSafari = !!navigator.userAgent.match(/Version\/[\d]+.*Safari/);
	const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	if(isSafari && iOS){
		document.body.classList.add('iOSsafari');
	}
};

exports.onRouteUpdateDelayed = () => {
//   console.log("We can show loading indicator now")
}

exports.onPreRouteUpdate = ({ location }) => {
	document.body.classList.add('loading');

	//DETECT FROM WORK CLASS AND DETECT IF THE PAGE IS A WORK PAGE ( LANDING OR NOT ) AND DONT OVERRIDE IT
	if (document.body.classList.contains('fromwork') && location.pathname.includes('works/')) {
		document.body.classList.add('fromwork');
	} else {
		document.body.classList.remove('fromwork');
	}

	if (!('ontouchstart' in document.documentElement)) {
		document.body.classList.add('onhover');
	}

    const isSafari = !!navigator.userAgent.match(/Version\/[\d]+.*Safari/);
	const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	if(isSafari && iOS){
		document.body.classList.add('iOSsafari');
	}
};
