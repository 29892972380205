module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MALT Studio","short_name":"MALTstudio","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/icon/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-133749685-2","exclude":["/@deploystatus"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"backgroundColor":"transparent"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
