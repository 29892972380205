import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Transition from 'components/transition';
import Loader from './loader';
import Img from 'gatsby-image';
import 'stylesheet/main.scss';

// Load Work Transition that doesnt get unloaded on page change
const WorksBg = () => (
	<StaticQuery
		query={query}
		render={(data) => {
			const ImageBGProps = {
				style: {
					margin: '0',
					width: '100%',
					height: '100%'
				}
			};
			return (
				<div id="WorksBg">
					{data.all.edges.map(({ node }) => {
						return (
							<div key={node.id}>
								{node.frontmatter.imagelist[0].image && (
									<Img
										{...ImageBGProps}
										className="fitheight"
										fluid={node.frontmatter.imagelist[0].image.childImageSharp.fluid}
										alt={node.frontmatter.imagelist[0].title}
									/>
								)}
							</div>
						);
					})}
				</div>
			);
		}}
	/>
);

export default class Master extends React.Component {
	componentDidMount() {
		if (typeof document !== `undefined`) document.body.style = '';
	}
	componentWillUnmount() {}
	render() {
		const props = this.props;
		return (
			<div className="master">
				<Loader />
				<Transition unmountOnExit mountOnEnter={false} location={props.children.props.location}>{props.children}</Transition>
				<WorksBg />
			</div>
		);
	}
}

const query = graphql`
	query {
		setting: markdownRemark(frontmatter: { issetting: { eq: true }, contenttype: { eq: "general_setting" } }) {
			frontmatter {
				title
				web_name
				header_title
			}
		}
		all: allMarkdownRemark(
			filter: { frontmatter: { issetting: { eq: false }, contenttype: { eq: "work" } } }
			sort: { fields: [frontmatter___date], order: DESC  }
		) {
			totalCount
			edges {
				node {
					id
					frontmatter {
						title
						imagelist {
							title
							image {
								childImageSharp {
									fluid(maxWidth: 1920) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
