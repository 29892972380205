import React from "react"
import {
    TransitionGroup,
    Transition as ReactTransition,
} from "react-transition-group"

//This variable will be responsible for our animation duration
const timein = 50;
const delayin = 200;
const timeout = 250;
const delayout = 0;

//This object contains basic styles for animation, but you can extend them to whatever you like. Be creative!
const getTransitionStyles = {
    entering: {
        position: 'absolute',
        opacity: 0,

    },
    entered: {
        transition: `opacity ${timein}ms ease-in-out ${delayin}ms`,
        opacity: 1,
    },
    exiting: {
        transition: `opacity ${timeout}ms ease-in-out ${delayout}ms`,
        opacity: 0,
    },
}

class Transition extends React.PureComponent {
    render() {
        //Destructuring props to avoid garbage this.props... in return statement
        const { children, location } = this.props
        console.log('transition render');
        const transitionExitEvent = new Event('transitionexit');
        return (
            //Using TransitionGroup and ReactTransition which are both
            //coming from  'react-transition-group' and are required for transitions to work
            <TransitionGroup component={null}>
                <ReactTransition
                //the key is necessary here because our ReactTransition needs to know when pages are entering/exiting the DOM
                    key={location.pathname}
                    timeout={{
                        enter: (timein + delayin),
                        exit: (timeout + delayout),
                    }}
                    onExited={node => {
                        document.body.dispatchEvent(transitionExitEvent);
                    }}
                >
                    {
                        //Application of the styles depending on the status of page(entering, exiting, entered) in the DOM
                        status => (
                        <div id="TransitionGroup"
                            style={{
                                ...getTransitionStyles[status],
                            }}
                        >
                            {children}
                        </div>
                    )}
                </ReactTransition>
            </TransitionGroup>
        )
    }
}

export default Transition
