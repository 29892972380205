import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const Loader = () => (
	<StaticQuery
		query={query}
		render={(data) => (
			<div id="MainLoader" className="fitheight">
				<div className="child_wrapper">
					<div className="malt_logo">
						{data.setting.frontmatter.header_title ? data.setting.frontmatter.header_title : 'MALT Studio'}
					</div>
					<div className="loader_blocker" />
				</div>
			</div>
		)}
	/>
);

export default Loader;

const query = graphql`
	query {
		setting: markdownRemark(frontmatter: { issetting: { eq: true }, contenttype: { eq: "general_setting" } }) {
			frontmatter {
				title
				web_name
				header_title
			}
		}
	}
`;
